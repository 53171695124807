import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import Image from "../../../components/image"
import { FaChevronRight } from "react-icons/fa"
import Helmet from "react-helmet"
import ListTop3 from "../components/listTop3"

const Page = ({ data, location, pageContext: { type_id, size_id, brand_id } }) => {
	const type = data.typesDrinkYaml
	const size = data.sizesDrinkYaml
	const brand = data.brandsDrinkYaml
	const sizeStr = '' 
	const itemStr = 'ドリンク'
	const unitStr = '100ml'
	const category = 'drink'
	const categoryIndex = '/drink/baby_tea_pet_all_all'
	let description = `${brand.description} ${type.description} ${size.description}${sizeStr} ${itemStr}${unitStr}あたりの最安値！`
	let preTitle = ``

  // allContentfulItemsを100ml当たりの価格が安い順に並び替える(Graphqlのソートだと100円以上の場合に並び替えが正しくできないため数値のソートを自前で行う)
	data.allContentfulItems.edges.sort(function(a,b){
		if((!a.node.itemList.length) && (!b.node.itemList.length)){
			return 0
		}
		// itemList無ければ後ろへ並べる
		else if((!a.node.itemList.length) && (b.node.itemList.length)){
			return 1
		}
		else if((a.node.itemList.length) && (!b.node.itemList.length)){
			return -1
		}
		return a.node.itemList[0].per - b.node.itemList[0].per
	})

	const cheap_item = data.allContentfulItems.edges.find(edge => {
		return edge.node.brandId === brand.cid
	})
	let cnt = 0

	// パンくずリストの構造化データ
	const jsonLd = {
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement": [{
			"@type": "ListItem",
			"position": 1,
			"name": "トップ",
			"item": "https://hula-la.com/"
		},{
			"@type": "ListItem",
			"position": 2,
			"name": itemStr,
			"item": "https://hula-la.com" + categoryIndex
		},{
			"@type": "ListItem",
			"position": 3,
			"name": type.description,
			"item": "https://hula-la.com/"+category+"/"+type.cid+".html"
		},{
			"@type": "ListItem",
			"position": 4,
			"name": size.description,
			"item": "https://hula-la.com/"+category+"/"+type.cid+"_"+size.cid+".html"
		},{
			"@type": "ListItem",
			"position": 5,
			"name": brand.description,
			"item": "https://hula-la.com"+location.pathname
		}]
	};

	return (
  <Layout>
			<>
				<Helmet>
     			<script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
   			</Helmet>
				<h2 id="top10" className="title is-6" style={{marginTop: `0.2rem`, marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>{brand.description} {type.description} {size.description}{sizeStr} Top10</h2>
				<p style={{fontSize:`0.8rem`, color: `#808080`}}><small>&nbsp;&nbsp;&nbsp;<strong>割引後にポイントと送料を含めた実質価格で</strong>{unitStr}あたりを計算！</small></p>
				<p style={{fontSize:`0.8rem`, color: `#808080`, marginBottom: `0.5rem`}}><small>&nbsp;&nbsp;&nbsp;（本ページのリンクには広告が含まれています）</small></p>
				<ListTop3 typeValue={type.cid} sizeValue={size.cid} brandValue={brand.cid} shopValue="all" articleUrl={`https://hula-la.com/${category}/${brand.cid}_${type.cid}_${size.cid}.html`} articleTitle={`${brand.description} ${type.description} ${size.description}`} />
				<h3 id={`${brand.cid}`} className="title is-6" style={{marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>{brand.description} 商品別</h3>
				<div className="columns is-multiline is-gapless">
					{data.allContentfulItems.edges.map(edge => {
						if(edge.node.brandId !== brand.cid){
							return <></>
						}
						const subbrand = data.allSubbrandsDrinkYaml.edges.find(n => {
							return (n.node.brand_id === edge.node.brandId) && (n.node.subbrand_id === edge.node.subbrandId)
						})
						const filename = `${brand.cid}_${type.cid}_${size.cid}_${subbrand.node.subbrand_id}.jpg`
						if(!edge.node.itemList.length) return <></>
						description += `${subbrand.node.description}${edge.node.itemList[0].per.toFixed(1)}円 `
						preTitle += preTitle === `` ? `${edge.node.itemList[0].per.toFixed(1)}円/${unitStr}` : ``
						cnt += 1
						const order = cnt
						return (
							<div className="column is-half">
								<Link to={`/${category}/${type.cid}_${size.cid}_${brand.cid}_${subbrand.node.subbrand_id}.html`}>
									<div className="card">
										<div className="card-content" style={{padding: `0.8rem`}}>
											<div className="media">
												<div className="media-left">
													<div>
														<span>{order}</span><span style={{fontSize:`0.5rem`}}>位</span>
													</div>
													<Image filename={filename} alt={`${brand.description}${subbrand.description}${type.description}タイプ${size.description}${sizeStr}の${itemStr}パッケージ画像`}　/>
												</div>
												<div className="media-content">
													<div className="content">
														<p>
															<span style={{fontSize:`0.7rem`}}><strong>{subbrand.node.description}</strong></span>
															<br />
															<span style={{color: `#ff0000`}} className="is-size-4">&nbsp;{edge.node.itemList[0].per.toFixed(1)}<span style={{fontSize:`0.7rem`}}> 円/{unitStr}</span> ～</span>
															<br />
															<span className="tag" style={{fontSize:`0.6rem`}}>{type.description}</span>
														</p>
													</div>
												</div>
												<div className="media-right">
													<span className="icon" style={{height: `2.5em`}}>
														<FaChevronRight />
													</span>
												</div>
											</div>
										</div>
									</div>
								</Link>
							</div>
							)
						})}
					</div>
				<br />
				<div className="content">
					<h3 id={`${brand.cid}_other`} className="title is-6" style={{marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>(参考) {brand.description}以外 {type.description} {size.description}</h3>
					<div className="columns is-multiline is-gapless">
						{data.allContentfulItems.edges.map(edge => {
							if(edge.node.brandId === brand.cid){
								return <></>
							}
							const brand_other = data.allBrandsDrinkYaml.edges.find(n => {
								return n.node.cid === edge.node.brandId
							})
							const subbrand = data.allSubbrandsDrinkYaml.edges.find(n => {
								return (n.node.brand_id === edge.node.brandId) && (n.node.subbrand_id === edge.node.subbrandId)
							})
							if(!brand_other) return <></>
							const filename = `${brand_other.node.cid}_${type.cid}_${size.cid}_${subbrand.node.subbrand_id}.jpg`
							if(!edge.node.itemList.length) return <></>
							description += `${subbrand.node.description}${edge.node.itemList[0].per.toFixed(1)}円 `
							return (
								<div className="column is-half">
									<Link to={`/${category}/${type.cid}_${size.cid}_${brand_other.node.cid}_${subbrand.node.subbrand_id}.html`}>
										<div className="card">
											<div className="card-content" style={{padding: `0.8rem`}}>
												<div className="media">
													<div className="media-left">
														<Image filename={filename} alt={`${brand_other.node.description}${subbrand.description}${type.description}タイプ${size.description}${sizeStr}の${itemStr}パッケージ画像`}　/>
													</div>
													<div className="media-content">
														<div className="content">
															<p>
																<span style={{fontSize:`0.7rem`}}><strong>{brand_other.node.description} {subbrand.node.description}</strong></span>
																<br />
																<span style={{color: `#ff0000`}} className="is-size-4">&nbsp;{edge.node.itemList[0].per.toFixed(1)}<span style={{fontSize:`0.7rem`}}> 円/{unitStr}</span> ～</span>
																<br />
																<span className="tag" style={{fontSize:`0.6rem`}}>{type.description}</span>
															</p>
														</div>
													</div>
													<div className="media-right">
														<span className="icon" style={{height: `2.5em`}}>
															<FaChevronRight />
														</span>
													</div>
												</div>
											</div>
										</div>
									</Link>
								</div>
							)
						})}
					</div>
				</div>
			</>
		<Seo title={`${brand.description} ${type.description} ${size.description}`} description={description} canonical={location.pathname} />
		<nav className="breadcrumb is-small" aria-label="breadcrumbs" style={{marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>
			<ul>
				<li><Link to="/">トップ</Link></li>
				<li><Link to={`${categoryIndex}`}>{itemStr}</Link></li>
				<li><Link to={`/${category}/${type.cid}.html`}>{type.description}</Link></li>
				<li><Link to={`/${category}/${type.cid}_${size.cid}.html`}>{size.description}</Link></li>
				<li className="is-active"><Link to="" aria-current="page">{brand.description}</Link></li>
			</ul>
		</nav>
  </Layout>
	)
}

export const query = graphql`
	query($brand_id: String! $type_id: String! $size_id: String!) {
		brandsDrinkYaml(cid: { eq: $brand_id}) {
			cid
			description
			maker
		}
		sizesDrinkYaml(cid: { eq: $size_id}) {
			cid
			description
		}
		typesDrinkYaml(cid: { eq: $type_id}) {
			cid
			description
		}
		allTypesDrinkYaml {
			edges {
				node {
					cid
					description
				}
			}
		}
		allContentfulItems(filter: {typeId: { eq: $type_id} sizeId: { eq: $size_id}}) {
			edges {
				node {
					brandId
					subbrandId
					typeId
					sizeId
					itemList {
						num
						shopId
						shop
						href
						per
						per_org
						price
						price_org
						sendPrice
						souryou
						txt
						point
						star
						review
						reviewUrl
					}
					updatedAt
				}
			}
		}
		allSubbrandsDrinkYaml {
			edges {
				node {
					brand_id
					subbrand_id
					description
					types {
						type_id 
						sizes {
							size_id
							num	
						}
					}
				}
			}
		}
		allBrandsDrinkYaml {
			edges {
				node {
					cid
					description
				}
			}
		}
	}
`

export default Page
